import React from "react";
import { useEffect,useRef,useLayoutEffect,useState,useCallback} from "react";
import {
  Player,
  PlaybackRateMenuButton,
  ControlBar,
  LoadingSpinner,
  ClosedCaptionButton,
  BigPlayButton,
  ForwardControl,
  ReplayControl,
  Shortcut,
} from "video-react";
import { base_url , Authorization_id} from "../../utilities";
import "video-react/dist/video-react.css"; 
import '../../homePageStyle.css';

export const StreamingVideoComponent = (props) => {
  const data= props?.data;
  const videoRef = useRef();
  const streaming_video_Component=useRef(null);
  const [captions,setCaptions]=useState("")
  const [videoUrl,setVideoUrl]=useState("");
  const [posterImage,setPoster]=useState("");

  useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({
          top:  streaming_video_Component.current?.offsetTop-20,
          left: 0,
           behavior: "smooth",
         });
       },350)
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    });

    // Observe the video element
    if (streaming_video_Component.current) {
      observer.observe(streaming_video_Component.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (streaming_video_Component.current) {
        observer.unobserve(streaming_video_Component.current);
      }
    };
  }, [videoUrl]);

  useEffect(()=>{
    fetchImage();
    fetchCaptions();
  },[])


  const fetchImage=async()=>{
   
    try {
      const response = await fetch(data?.TN);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const videoData = await response.blob();
      const url = URL.createObjectURL(videoData);
      
      setPoster(url);
    } catch (error) {
      console.error('Failed to fetch video:', error);
    }
  }

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Video is in the viewport
      } else {
        pauseVideo();
        // Video is not in the viewport
        
      }
    });
  };

 
  function convertSrtToVtt(srtData) {
    const parser = new RegExp(/(\d+)\n(\d{2}:\d{2}:\d{2}),(\d{3}) --> (\d{2}:\d{2}:\d{2}),(\d{3})\n([\s\S]*?(?=\n\n|$))/g);
    const vttArray = [];
    let match;
  
    while (match = parser.exec(srtData)) {
      const [fullMatch, id, startHours, startMilliseconds, endHours, endMilliseconds, text] = match;
      const startTime = parseTimeString(startHours, startMilliseconds);
      const endTime = parseTimeString(endHours, endMilliseconds);
  
      vttArray.push(`${id}
  ${formatTime(startTime)} --> ${formatTime(endTime)}
  ${text.replace(/\n/g, '\n')}`);
    }
  
    return `WEBVTT\n\n${vttArray.join('\n\n')}`;
  }
  
  function parseTimeString(hours, milliseconds) {
    const [h, m, s] = hours.split(':');
    return parseInt(h, 10) * 3600 + parseInt(m, 10) * 60 + parseInt(s, 10) + parseInt(milliseconds, 10) / 1000;
  }
  
  function formatTime(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    const ms = Math.floor((seconds - Math.floor(seconds)) * 1000);
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`;
    
  }
  
  const fetchCaptions = async () => {
    try {
      const response = await fetch(data?.srt)
      const srt_data= await response.text();
      const vttData = convertSrtToVtt(srt_data);
      const captionsContent = URL.createObjectURL(new Blob([vttData], { type: 'text/vtt' }));
      setCaptions(captionsContent)
    }  catch (error) {
      console.error('Error fetching captions:', error);
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  

const handlePlay=()=>{
  const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.load();
    }
}

  return (
    <div data-testid="VideoComponent_content">
    <div className="col-12 video-component custm_shadow_cls bg-body video-streaming" ref={streaming_video_Component}  onEnded={handlePlay}>
        <h2>{data?.title_data}</h2>
        
<Player id={`my-player_${props.i}`} ref={videoRef}  poster={posterImage}>
  <Shortcut dblclickable={false} clickable={true}/>
      <source
       src={"https://d3qn28bbjwmhwt.cloudfront.net/M0_L0_V0_Course+Introduction.mp4"}
        type="video/mp4"
      />
      <track
                          kind="captions"
                          // src={URL.createObjectURL(new Blob([captions]))}
                          src={captions}
                          srcLang="en"
                          label="On"
                          // className="track"
                          default
                        />
     
<LoadingSpinner/>
       <BigPlayButton position="center"  />
             <ControlBar  >
             
             <ReplayControl seconds={10} order={2.2} />
             <ForwardControl seconds={10} order={3.2} />
             
             {!captions ?<></>:<ClosedCaptionButton order={7}  />}
             <PlaybackRateMenuButton rates={[ 2, 1.75 ,1.5 ,1.25, 1, 0.75 , 0.5]}  order={8} />
         </ControlBar>
    </Player> 
    </div>
     </div>
 
  );
};
